import React from 'react'
import { graphql } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import get from 'lodash/get'

import Layout from '../../components/Layout'

class termsAndConditions extends React.Component {
  render() {
    const pageSEO = get(this, 'props.data.allContentfulPages.edges[0].node')
    return (
      <Layout>
        <HeaderSEO pageSEO={pageSEO} />
        {/* <!-- ========== PRIVACY POLICY SECTION ========== --> */}
        <section className="mt-5 pt-5 pb-5 px-2">
          <div className="container-1">
            <div className="row">
              <div className="col-md-12">
                <h1 className="underline h3-like text-uppercase">
                  Terms & conditions
                </h1>
                <p>
                  PLEASE READ THESE TERMS AND CONDITIONS BEFORE USING THIS SITE
                </p>
                <p>
                  These terms of use (together with the documents referred to in
                  it) tells you the terms on which you may make use of our
                  website www.lenses.io (our site). Use of our site includes
                  accessing and browsing.
                </p>
                <p>
                  Access to our site is provided by Lenses.io. For the purposes
                  of these terms of use and the other documents referred to,
                  Lenses.io shall mean Lenses.io Ltd and its affiliates,
                  subsidiaries and branch offices, and their officers,
                  directors, partners, consultants and employees. &nbsp;
                </p>
                <p>
                  Please read these terms of use carefully before you start to
                  use our site, as these will apply to your use of our site. We
                  recommend that you print a copy of this for future reference.{' '}
                </p>
                <p>
                  By using our site, you confirm that you accept these terms of
                  use and that you agree to comply with them.{' '}
                </p>
                <p>
                  If you do not agree to these terms of use, you must not use
                  our site.
                </p>
                <h4>OTHER APPLICABLE TERMS</h4>
                <p>
                  These terms of use refer to the following additional terms,
                  which also apply to your use of our site:
                </p>
                <p>
                  Our <a href="/legals/policy/">Privacy Policy</a>, which sets
                  out the terms on which we process any personal data we collect
                  from you, or that you provide to us. By using our site, you
                  consent to such processing and you warrant that all data
                  provided by you is accurate.{' '}
                </p>
                <p>
                  Our <a href="/legals/cookies/">Cookie Policy</a>, which sets
                  out information about the cookies on our site.
                </p>
                <h4>INFORMATION ABOUT US</h4>
                <p>
                  The website lenses.io is a site operated by Lenses.io
                  (&ldquo;We&rdquo;). We are a private limited company
                  incorporated in England with registration number 09975716
                  whose registered office is at C/O Corporation Service Company
                  (Uk) Limited, 5 Churchill Place, 10th Floor, London, E14 5HU,
                  United Kingdom. Our VAT number is GB 231980705.
                  <table className="table table-bordered mt-3">
                    <tbody className="border-pricing-table">
                      <tr>
                        <th className="pl-4 border-right">Incorporated in</th>
                        <td className="pl-4 border-right">England</td>
                      </tr>
                      <tr>
                        <th className="pl-4 border-right">
                          Registration number
                        </th>
                        <td className="pl-4 border-right">09975716</td>
                      </tr>
                      <tr>
                        <th className="pl-4 border-right">
                          Registered office address
                        </th>
                        <td className="pl-4 border-right">
                          C/O Corporation Service Company (Uk) Limited, 5
                          Churchill Place, 10th Floor, London, United Kingdom,
                          E14 5HU
                        </td>
                      </tr>
                      <tr>
                        <th className="pl-4 border-right">VAT Number</th>
                        <td className="pl-4 border-right">GB 231980705</td>
                      </tr>
                    </tbody>
                  </table>
                  The company is also incorporated in the state of Delaware.
                </p>
                <h4>CHANGES TO THESE TERMS</h4>
                <p>
                  We may revise these terms of use at any time by amending this
                  page. &nbsp;Please check this page from time to time to take
                  notice of any changes we made, as they are binding on you.{' '}
                </p>
                <h4>CHANGES TO OUR SITE</h4>
                <p>
                  We may update our site from time to time, and may change the
                  content at any time. However, please note that any of the
                  content on our site may be out of date at any given time, and
                  we are under no obligation to update it.
                </p>
                <p>
                  We do not guarantee that our site, or any content on it, will
                  be free from errors or omissions.
                </p>
                <h4>ACCESSING OUR SITE</h4>
                <p>Our site is made available free of charge.</p>
                <p>
                  We do not guarantee that our site, or any content on it, will
                  always be available or be uninterrupted. Access to our site is
                  permitted on a temporary basis. We may suspend, withdraw,
                  discontinue or change all or any part of our site without
                  notice. We will not be liable to you if for any reason our
                  site is unavailable at any time or for any period.
                </p>
                <p>
                  You are responsible for making all arrangements necessary for
                  you to have access to our site.
                </p>
                <p>
                  You are also responsible for ensuring that all persons who
                  access our site through your internet connection are aware of
                  these terms of use and other applicable terms and conditions,
                  and that they comply with them.
                </p>
                <h4>INTELLECTUAL PROPERTY RIGHTS</h4>
                <p>
                  Unless otherwise indicated, we are the owner or the licensee
                  of all intellectual property rights in our site, and in the
                  material published on it.&nbsp; Those works are protected by
                  copyright laws and treaties around the world. All such rights
                  are reserved.
                </p>
                <p>
                  You may print off one copy, and may download extracts, of any
                  page(s) from our site for your personal use and you may draw
                  the attention of others within your organisation to content
                  posted on our site.
                </p>
                <p>
                  You must not modify the paper or digital copies of any
                  materials you have printed off or downloaded in any way, and
                  you must not use any illustrations, photographs, video or
                  audio sequences or any graphics separately from any
                  accompanying text.
                </p>
                <p>
                  Our status (and that of any identified contributors) as the
                  authors of content on our site must always be acknowledged.
                </p>
                <p>
                  You must not use any part of the content on our site for
                  commercial purposes.
                </p>
                <p>
                  If you print off, copy or download any part of our site in
                  breach of these terms of use, your right to use our site will
                  cease immediately and you must, at our option, return or
                  destroy any copies of the materials you have made.
                </p>
                <h4>NO RELIANCE ON INFORMATION</h4>
                <p>
                  The content on our site is provided for general information
                  only. It is not intended to amount to advice on which you
                  should or can rely. You must obtain professional or specialist
                  advice before taking, or refraining from, any action on the
                  basis of the content on our site.
                </p>
                <p>
                  Although we make reasonable efforts to update the information
                  on our site, we make no representations, warranties or
                  guarantees, whether express or implied, that the content on
                  our site is accurate, complete or up-to-date.
                </p>
                <h4>THE SERVICES</h4>
                <p>
                  As part of the Services, Lenses.io may offer training, support
                  and professional services to help you install, configure,
                  optimize, tune and run computer programs. Lenses.io also
                  develops, distributes and maintains proprietary and open
                  source software products for its customers. The Services may
                  be provided by Lenses.io or its third-party service providers.
                  Lenses.io reserves the right to change any and all content
                  contained in the Services and to modify, suspend or
                  discontinue the Services or any features or functionality of
                  the Services at any time without notice and without obligation
                  or liability to you.
                </p>
                <h4>
                  ADVERTISEMENTS AND PROMOTIONS; THIRD-PARTY PRODUCTS AND
                  SERVICES
                </h4>
                <p>
                  We may run advertisements and promotions from third parties on
                  the Services or may otherwise provide information or links to
                  third-party products or services on the Services. Your
                  business dealings or correspondence with, or participation in
                  promotions of our advertisers and any terms, conditions,
                  warranties or representations associated with such dealings or
                  promotions, are solely between you and such third party. We
                  are not responsible or liable for any loss or damage of any
                  sort incurred as a result of any such dealings or promotions
                  or as the result of the presence of such third-party
                  advertisers or third-party information on the Services.
                </p>
                <h4>NO WARRANTY</h4>
                <p>
                  The Website is provided on an “as is” and “as available”
                  basis. Use of the Website is at your own risk. To the maximum
                  extent permitted by applicable law, the Website is provided
                  without warranties of any kind, whether express or implied,
                  including, but not limited to, implied warranties of
                  merchantability, fitness for a particular purpose, or
                  non-infringement. No advice or information, whether oral or
                  written, obtained by you from Lenses.io or through the Website
                  will create any warranty not expressly stated herein. Without
                  limiting the foregoing, Lenses.io, its subsidiaries, its
                  affiliates, and its licensors do not warrant that the content
                  is accurate, reliable or correct; that the Website will meet
                  your requirements; that the Website will be available at any
                  particular time or location, uninterrupted or secure; that any
                  defects or errors will be corrected; or that the Website is
                  free of viruses or other harmful components. Any content
                  downloaded or otherwise obtained through the use of the
                  Website is downloaded at your own risk and you will be solely
                  responsible for any damage to your computer system or mobile
                  device or loss of data that results from such download or your
                  use of the Website. Lenses.io does not warrant, endorse,
                  guarantee, or assume responsibility for any product or Website
                  advertised or offered by a third party through the Website or
                  any hyperlinked Website or Website, and Lenses.io will not be
                  a party to or in any way monitor any transaction between you
                  and third-party providers of products or services.
                </p>
                <h4>LIMITATION OF LIABILITY</h4>
                <p>
                  Nothing in these terms of use excludes or limits our liability
                  for death or personal injury arising from our negligence, or
                  our fraud or fraudulent misrepresentation, or any other
                  liability that cannot be excluded or limited by English law.
                </p>
                <p>
                  To the extent permitted by law, we exclude all conditions,
                  warranties, representations or other terms which may apply to
                  our site or any content on it, whether express or implied.{' '}
                </p>
                <p>
                  We will not be liable to any user for any loss or damage,
                  whether in contract, tort (including negligence), breach of
                  statutory duty, or otherwise, even if foreseeable, arising
                  under or in connection with:
                </p>
                <ul className="pb-2">
                  <li>use of, or inability to use, our site; </li>
                  <li>
                    use of or reliance on any content displayed on our site;{' '}
                  </li>
                  <li>loss of profits, sales, business, or revenue;</li>
                  <li>business interruption;</li>
                  <li>loss of anticipated savings;</li>
                  <li>loss of data;</li>
                  <li>
                    loss of business opportunities, goodwill or reputation; or
                  </li>
                  <li>
                    any indirect, special or consequential loss or damage.
                  </li>
                </ul>
                <p>
                  We will not be liable for any loss or damage caused by a
                  virus, distributed denial-of-service attack, or other
                  technologically harmful material that may infect your computer
                  equipment, computer programs, data or other proprietary
                  material due to your use of our site or to your downloading of
                  any content on it, or on any website linked to it.
                </p>
                <p>
                  We assume no responsibility for the content of websites linked
                  on our site. Such links should not be interpreted as
                  endorsement by us of those linked websites. We will not be
                  liable for any loss or damage that may arise from your use of
                  them.
                </p>
                <h4>VIRUSES</h4>
                <p>
                  We do not guarantee that our site will be secure or free from
                  bugs or viruses.
                </p>
                <p>
                  You are responsible for configuring your information
                  technology, computer programmes and platform in order to
                  access our site. You should use your own virus protection
                  software.
                </p>
                <p>
                  You must not misuse our site by knowingly introducing viruses,
                  trojans, worms, logic bombs or other material which is
                  malicious or technologically harmful. You must not attempt to
                  gain unauthorised access to our site, the server on which our
                  site is stored or any server, computer or database connected
                  to our site. You must not attack our site via a
                  denial-of-service attack or a distributed denial-of service
                  attack. By breaching this provision, you would commit a
                  criminal offence under the Computer Misuse Act 1990. We will
                  report any such breach to the relevant law enforcement
                  authorities and we will co-operate with those authorities by
                  disclosing your identity to them. In the event of such a
                  breach, your right to use our site will cease immediately.
                </p>
                <h4>LINKING TO OUR SITE</h4>
                <p>
                  You may link to our home page, provided you do so in a way
                  that is fair and legal and does not damage our reputation or
                  take advantage of it.
                </p>
                <p>
                  You must not establish a link in such a way as to suggest any
                  form of association, approval or endorsement on our part where
                  none exists.
                </p>
                <p>
                  Our site must not be framed on any other site, nor may you
                  create a link to any part of our site other than the home
                  page. We reserve the right to withdraw linking permission
                  without notice.
                </p>
                <p>
                  If you wish to make any use of content on our site other than
                  that set out above, please contact{' '}
                  <span style={{ fontFamily: 'CeraPro, arial' }}>
                    <b>
                      info
                      <span style={{ width: '1px', display: 'inline-block' }}>
                        {' '}
                      </span>
                      @lenses.io
                    </b>
                  </span>{' '}
                </p>
                <h4>THIRD PARTY LINKS AND RESOURCES IN OUR SITE</h4>
                <p>
                  Where our site contains links to other sites and resources
                  provided by third parties (“External Sites”), these links are
                  provided for your information and convenience only.&nbsp;
                  Accordingly, we make no representations concerning the content
                  of any External Site. The fact that Lenses.io has provided a
                  link to the External Site does not constitute an endorsement,
                  authorization, sponsorship, or affiliation by Lenses.io with
                  respect to the External Site, its owners, or its providers.
                  Lenses.io has not tested any information, software, or
                  products found on any of the External Sites and therefore does
                  not make any representations with respect thereto, including
                  any representations regarding the content or sponsors of the
                  External Site, or the suitability or appropriateness of the
                  products or transactions described therein.
                </p>
                <p>
                  We have no control over the contents of any External Sites.
                </p>
                <h4>INDEMNITY</h4>
                <p>
                  You agree to defend, indemnify and hold harmless Lenses.io and
                  its subsidiaries, agents, licensors, managers, and other
                  affiliated companies, and their employees, contractors,
                  agents, officers and directors, from and against any and all
                  claims, damages, obligations, losses, liabilities, costs or
                  debt, and expenses (including but not limited to attorney’s
                  fees) arising from: (i) your use of and access to the Website,
                  including any data or content transmitted or received by you;
                  (ii) your violation of any term of this Agreement, including
                  without limitation your breach of any of the representations
                  and warranties above; (iii) your violation of any third-party
                  right, including without limitation any right of privacy or
                  intellectual property rights; (iv) your violation of any
                  applicable law, rule or regulation; (v) any content that is
                  submitted by you or someone on your behalf, including without
                  limitation misleading, false, or inaccurate information; (vi)
                  your willful misconduct; or (vii) any other party’s access and
                  use of the Website with your unique username, password or
                  other appropriate security code.
                </p>
                <h4>APPLICABLE LAW</h4>
                <p>
                  If you are a consumer, please note that these terms of use,
                  its subject matter and its formation, are governed by English
                  law. You and we both agree to that the courts of England will
                  have non-exclusive jurisdiction.
                </p>
                <p>
                  If you are a business, these terms of use, its subject matter
                  and its formation (and any non-contractual disputes or claims)
                  are governed by English law. We both agree to the exclusive
                  jurisdiction of the courts of England.
                </p>
                <h4>CONTACT US</h4>
                <p>
                  To contact us concerning these terms of use, please email{' '}
                  <span style={{ fontFamily: 'CeraPro, arial' }}>
                    <b>
                      info
                      <span style={{ width: '1px', display: 'inline-block' }}>
                        {' '}
                      </span>
                      @lenses.io
                    </b>
                  </span>{' '}
                </p>
                <h4>OTHERS</h4>
                <p>
                  HADOOP, KAFKA, Apache, Avro. Flume, Hadoop, HBase, Hive,
                  Kafka, Kudu, Lucene, Mahout. Oozie, Parquet, Pig, Sentry,
                  Solr, Spark, Sqoop, Impala, and ZooKeeper are registered
                  trademarks or trademarks of the Apache Software Foundation.
                  Reference to any products, services, processes or other
                  information, by trade name, trademark, manufacturer, supplier
                  or otherwise does not constitute or imply endorsement,
                  sponsorship or recommendation by Lenses.io.
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
export default termsAndConditions

export const pageQuery = graphql`
  query LegalsTermsSEO {
    allContentfulPages(
      filter: { pageReference: { pageIdentifier: { eq: "Legals_Terms" } } }
    ) {
      edges {
        node {
          title {
            title
          }
          keywords {
            keywords
          }
          description {
            description
          }
          oGimage {
            fluid(quality: 100) {
              src
            }
          }
        }
      }
    }
  }
`
